import { template as template_8efe22dd35274979b84860974ba27647 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8efe22dd35274979b84860974ba27647(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
