import { template as template_bd447456a19c484499f97e0a295a887c } from "@ember/template-compiler";
const FKLabel = template_bd447456a19c484499f97e0a295a887c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
