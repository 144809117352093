import { template as template_c41e20ca184247099a7ceab2a4475f29 } from "@ember/template-compiler";
const WelcomeHeader = template_c41e20ca184247099a7ceab2a4475f29(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
